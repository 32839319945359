import { graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

const SEO = ({
  title,
  description,
  url,
  image,
  isBlogPost: isPost,
  lang,
  keywords,
}) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        const actualTitle =
          title !== undefined
            ? title + " | " + data.site.siteMetadata.title
            : data.site.siteMetadata.title
        const actualKeywords =
          keywords.length > 0
            ? keywords.join(", ")
            : "waravix, hr, consulting, it, jobs, recruiting, viajar, mundo, dinero"

        const actualImage =
          image !== undefined ? image : data.logoImage.childImageSharp.fixed
        const actualImagePath =
          image !== undefined
            ? image.src || image.srcWebp
            : data.logoImage.childImageSharp.fixed.src
        const actualImageUrl = "https://www.waravix.com" + actualImagePath
        const actualImagePresentationWidth = actualImage.width
        const actualImagePresentationHeight = actualImage.height

        // console.info("imagePath: " + actualImagePath)
        // console.info("data.logoImage.childImageSharp.fluid: " + data.logoImage.childImageSharp.fluid)
        // console.info("actualTitle: " + actualTitle)
        // console.info("metaDescription: " + metaDescription)
        // console.info("actualKeywords: " + actualKeywords)
        // console.info("actualImageUrl: " + actualImageUrl)
        // console.info("image.presentationWidth: " + actualImagePresentationWidth)
        // console.info("image.presentationHeight: " + actualImagePresentationHeight)

        return (
          <>
            <Helmet encodeSpecialCharacters={false}>
              {/* General tags */}
              <title>{actualTitle}</title>
              <link rel="canonical" href={url} />
              <meta name="description" content={metaDescription} />
              <meta name="image" content={actualImagePath.src} />
              <meta name="keywords" content={actualKeywords} />

              {/* OpenGraph tags */}
              <meta property="og:url" content={url} />
              {isPost ? (
                <meta property="og:type" content="article" />
              ) : (
                <meta property="og:type" content="website" />
              )}
              <meta property="og:title" content={actualTitle} />
              <meta property="og:description" content={metaDescription} />
              <meta property="og:image:url" content={actualImageUrl} />
              <meta property="og:image:alt" content={"Waravix"} />
              <meta property="og:image:secure_url" content={actualImageUrl} />
              <meta property="og:image:type" content="image/jpeg" />
              <meta
                property="og:image:width"
                content={actualImagePresentationWidth}
              />
              <meta
                property="og:image:height"
                content={actualImagePresentationHeight}
              />

              {/* Twitter Card tags */}
              <meta name="twitter:card" content="summary_large_image" />
              <meta
                name="twitter:creator"
                content={data.site.siteMetadata.author}
              />
              <meta name="twitter:title" content={actualTitle} />
              <meta name="twitter:description" content={metaDescription} />
              <meta name="twitter:image" content={actualImageUrl} />
            </Helmet>
          </>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: "es_ES",
  keywords: [
    "recruiting",
    "hr",
    "candidates",
    "IT",
    "professionals",
    "recruiter",
  ],
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  image: PropTypes.object,
  lang: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    logoImage: file(absolutePath: { regex: "/logo-claim-main/" }) {
      childImageSharp {
        fixed(width: 1200, quality: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
