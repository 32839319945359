import { Link } from "gatsby"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Hidden } from "@material-ui/core"
import InstagramIcon from "@material-ui/icons/Instagram"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import FacebookIcon from "@material-ui/icons/Facebook"
import FooterTop from "../../images/steps/svg/footer-top.inline.svg"
import { colors } from "../../utils"
import Button from "@material-ui/core/Button"
import Img from "gatsby-image"


const LayoutFooter = ({ logo }) => {
  const useStyles = makeStyles((theme) => ({
    footer: {
      color: colors.GRAY_LIGHT,
      backgroundColor: colors.GRAY_DARK,
      minHeight: "10em",
      padding: "2em 0",
      overflow: `hidden`,
    },
    footerItem: {
      "& a": {
        color: "white",
        textDecoration: "none",
      },
      [theme.breakpoints.down("md")]: {
        padding: "0em .5em 1.5em .5em",
      },
      [theme.breakpoints.up("md")]: {
        padding: ".5em",
      },
      textAlign: "center",
    },
  }))
  const classes = useStyles()

  return (
    <>
      <Hidden mdDown>
        <FooterTop style={{ marginBottom: "-2em" }}/>
      </Hidden>
      <Hidden mdUp>
        <FooterTop style={{ marginBottom: "-1em", marginTop: "3em" }} />
      </Hidden>

      <footer className={classes.footer}>
        <Grid container spacing={2} justify={"space-evenly"} alignItems={"center"}>

          {/* Logo */}
          <Grid container direction={"column"} alignItems={"center"} style={{ paddingBottom: "2em" }}>
            <Grid item>
              <Link to={"/"}>
                <Img fixed={logo} alt={"waravix logo"}/>
              </Link>
            </Grid>
          </Grid >

          {/* Legal */}
          <Hidden mdDown>
            <Grid md={1} container direction={"column"}>
              <Grid item className={classes.footerItem}>
                <Link to={"/terms"}>
                  {"Términos"}
                </Link>
              </Grid>
              <Grid item className={classes.footerItem}>
                <Link to={"/privacy"}>
                  {"Privacidad"}
                </Link>
              </Grid>
            </Grid>
          </Hidden>


          {/* Social */}
          <Grid xs={12} md={1} container direction={"row"} alignContent={"center"} justify={"center"}>
            <Grid item className={classes.footerItem}>
              <a href={"https://www.linkedin.com/company/waravix"}>
                <LinkedInIcon fontSize={"large"} name={"linkedin"} />
              </a>
            </Grid>
            <Grid item className={classes.footerItem}>
              <a href={"https://www.facebook.com/waravix"}>
                <InstagramIcon fontSize={"large"} name={"instagram"} />
              </a>
            </Grid>
            <Grid item className={classes.footerItem}>
              <a href={"https://www.facebook.com/waravix"}>
                <FacebookIcon fontSize={"large"} name={"facebook"} />
              </a>
            </Grid>
          </Grid>

          {/*Company */}
          <Grid xs={12} md={1} container direction={"row"} alignContent={"center"} justify={"center"}
                className={classes.footerItem}>
            <Grid item>
              <Link to={"/contact"}>
                <Button variant={"outlined"} size={"large"} style={{ color: "white", borderColor: "white" }}>
                  {"Contactanos"}
                </Button>
              </Link>
            </Grid>
          </Grid>

          <Hidden mdUp>
            <Grid xs={12} container direction={"column"}>
              <Grid item className={classes.footerItem}>
                <Link to={"/terms"}>
                  {"Términos"}
                </Link>
              </Grid>
              <Grid item className={classes.footerItem}>
                <Link to={"/privacy"}>
                  {"Privacidad"}
                </Link>
              </Grid>
            </Grid>
          </Hidden>

        </Grid>
      </footer>
    </>
  )
}

export default LayoutFooter