export const colors = {
  GREEN: "#26474e",
  GREEN_PETROLEUM_WARAVIX: "#063c47",
  GREEN_PETROLEUM_DARK: "#043038",
  GREEN_PETROLEUM_80DARKER: "#010c0e",
  GREEN_PETROLEUM_90DARKER: "#010607",
  WHITE_10: "#f1f1f1",
  WHITE_90: "#f9f9f9",
  GRAY_LIGHT: "#f6f6f6",
  GRAY_DARK: "#242526",
  GRAY_LIGHT_VIOLET: "#f0f4f6",
  MAGENTA_WARAVIX: "#ad398a",
  MAGENTA_LIGHT: "#c3329f",
}
