import React from "react"
import BackgroundImage from "gatsby-background-image"
import { Box, makeStyles } from "@material-ui/core"

const Hero = ({ imageData, children }) => {
  const useStyles = makeStyles((theme) => ({
    hero: {
      width: "auto",
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
  }))

  const classes = useStyles()

  return (
    <Box style={{ filter: "brightness(90%)" }}>
      <BackgroundImage
        Tag="section"
        className={classes.hero}
        fluid={imageData}
        // backgroundColor={`#040e18`}
        style={{ height: "100vh" }}
      >
        <>
          {children}
        </>
      </BackgroundImage>
    </Box>
  )
}

export default Hero