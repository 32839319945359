import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql, Link, useStaticQuery } from "gatsby"
import "../../css/typography.css"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined"
import { makeStyles } from "@material-ui/core/styles"
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined"
import ModeCommentOutlinedIcon from "@material-ui/icons/ModeCommentOutlined"
import {
  Box,
  Container,
  Divider,
  Grid,
  isWidthUp,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  SwipeableDrawer,
  useScrollTrigger,
  withWidth,
} from "@material-ui/core"
import { colors } from "../../utils"
import LayoutFooter from "../common/footer"
import Img from "gatsby-image"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CoderVideo from "../../videos/coder.mp4"
import Hero from "../index/hero"
import { Waypoint } from "react-waypoint"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#333333",
    backgroundColor: "#f9f9f9",
    fontFamily: "Stag Sans, Lato, georgia, serif",
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "16px",
    overflow: `hidden`,
  },
  body: {
    // minHeight: "70vh",
  },
  computerMenu: {
    color: "#fafafa",
    position: "fixed",
    padding: "1em 0",
    top: 0,
    left: 0,
    zIndex: 1,
    "& a": {
      textDecoration: "none",
    },
  },
  mobileMenu: {
    flexShrink: 0,
    "& a": {
      color: colors.GREEN_PETROLEUM_90DARKER,
      textDecoration: "none",
    },
  },
  menuButton: {
    color: colors.WHITE_10,
    textShadow: "0px 0px 3px #333333, 1px 1px 3px #333333",
  },
  main: {
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      top: "45vh",
    },
    [theme.breakpoints.up("md")]: {
      top: "40vh",
    },
  },
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: "2.75em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.75em",
    },
    color: colors.WHITE_90,
    textShadow: "2px 2px 2px #333333, 2px 2px 2px #333333, 2px 2px 2px #333333, 2px 2px 2px #333333",
    margin: "0",
    fontWeight: "800",
    textTransform: "uppercase",
    lineHeight: "1.05em",
  },
  informationButton: {
    color: colors.WHITE_90,
    borderColor: colors.WHITE_90,
    backgroundColor: "#792861",
    textAlign: "center",
    margin: "1em",
    "&:hover": {
      backgroundColor: "#792861",
      boxShadow: "none",
    },
  },
}))

const Layout = ({ currentPage, width, infoRef, contactRef, backgroundColor, children }) => {
  const classes = useStyles()
  const handleInfoClick = () => infoRef.current.scrollIntoView({ behavior: "smooth" })
  const handleContactClick = () => contactRef.current.scrollIntoView({ behavior: "smooth" })

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      mainLogo: file(absolutePath: { regex: "/logo-main/" }) {
          childImageSharp {
          fixed(width: 192, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      mainLogoClaim: file(absolutePath: { regex: "/logo-claim-main/" }) {
          childImageSharp {
          fixed(width: 192, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      blackLogo: file(absolutePath: { regex: "/logo-claim-black/" }) {
        childImageSharp {
          fluid(maxWidth: 192, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whiteLogo: file(absolutePath: { regex: "/logo-white-no-claim/" }) {
        childImageSharp {
          fixed(width: 192, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      whiteClaimLogo: file(absolutePath: { regex: "/logo-white-claim/" }) {
        childImageSharp {
          fixed(width: 192, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      heroImage: file(absolutePath: { regex: "/network/" }) {
        childImageSharp {
          fluid(maxWidth: 1366, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [drawerIsOpen, toggleDrawerIsOpen] = useState(false)
  const [transparentBackground, toggleTransparentBackground] = useState(false)
  const toggleBackground = () => (toggleTransparentBackground(!transparentBackground))
  const toggleDrawer = () => (toggleDrawerIsOpen(!drawerIsOpen))

  const renderContactButton = (currentPage) => {
    if (currentPage === "home") {
      return (
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleContactClick}
                className={classes.menuButton}
                style={{
                  color: transparentBackground ? colors.WHITE_10 : "#333333",
                  textShadow: transparentBackground ? "0px 0px 3px #333333, 1px 1px 3px #333333" : "none",
                }}>
          {"Contacto"}
        </Button>
      )
    } else {
      return (
        <Link to={"/contact"}>
          <Button aria-controls="simple-menu" aria-haspopup="true" className={classes.menuButton}
                  style={{
                    color: transparentBackground ? colors.WHITE_10 : "#333333",
                    textShadow: transparentBackground ? "0px 0px 3px #333333, 1px 1px 3px #333333" : "none",
                  }}>
            {"Contacto"}
          </Button>
        </Link>
      )
    }
  }

  // Computer
  if (isWidthUp("md", width)) {
    return (
      <Box className={classes.root}>
        <Box className={classes.body}>
          <Grid container justify="space-around" alignItems="center"
                className={classes.computerMenu}
                style={{ backgroundColor: transparentBackground ? "transparent" : "#f9f9f9" }}>
            <Grid item>
              <Link to={"/"}>
                <Img
                  fixed={currentPage === "home"
                    ? transparentBackground ? data.whiteLogo.childImageSharp.fixed : data.mainLogo.childImageSharp.fixed
                    : data.mainLogoClaim.childImageSharp.fixed}
                  style={{ filter: "brightness(90%)" }}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link to={"/"}>
                <Button aria-controls="simple-menu" aria-haspopup="true" className={classes.menuButton}
                        style={{
                          color: transparentBackground ? colors.WHITE_10 : "#333333",
                          textShadow: transparentBackground ? "0px 0px 3px #333333, 1px 1px 3px #333333" : "none",
                        }}>
                  {"Inicio"}
                </Button>
              </Link>
              <Link to={"/jobs"}>
                <Button aria-controls="simple-menu" aria-haspopup="true" className={classes.menuButton}
                        style={{
                          color: transparentBackground ? colors.WHITE_10 : "#333333",
                          textShadow: transparentBackground ? "0px 0px 3px #333333, 1px 1px 3px #333333" : "none",
                        }}>
                  {"Búsquedas activas"}
                </Button>
              </Link>
              {
                renderContactButton(currentPage)
              }
            </Grid>
          </Grid>

          {
            currentPage === "home" &&
            <>
              <Waypoint onEnter={toggleBackground} onLeave={toggleBackground}>
                <Box style={{ filter: "brightness(90%)" }}>
                  <video autoPlay loop muted style={{
                    top: 0,
                    left: 0,
                    width: "100vw",
                    overflow: "hidden",
                    height: "100vh",
                    // position: "fixed",
                    objectFit: "cover",
                  }}>
                    <source src={CoderVideo} type="video/mp4" />
                  </video>
                </Box>
              </Waypoint>
              <Grid container direction="column" justify="center" alignItems="center" className={classes.main}>
                <p className={classes.title}>{"Conectando"}</p>
                <p className={classes.title}>{"Talentos"}</p>
                <Button variant="outlined" endIcon={<ExpandMoreIcon />}
                        onClick={handleInfoClick}
                        className={classes.informationButton}
                        style={{ border: "1px solid" }}
                >
                  {"Más información"}
                </Button>
              </Grid>
            </>
          }
          <Box>
            {children}
          </Box>
        </Box>
        <LayoutFooter logo={data.whiteLogo.childImageSharp.fixed} />
      </Box>
    )
  }

  // Mobile
  return (
    <Box className={classes.root}>
      <Slide direction="down" in={useScrollTrigger}>
        <AppBar variant={"outlined"} position={"fixed"} color={transparentBackground ? "transparent" : "#f9f9f9"}
                style={{ color: "#0b3c47", border: "none" }}>
          <Toolbar>
            <Link to={"/"} style={{ flexGrow: 1, filter: "brightness(90%)" }}>
              <Img fixed={currentPage === "home"
                ? transparentBackground ? data.whiteLogo.childImageSharp.fixed : data.mainLogo.childImageSharp.fixed
                : data.mainLogoClaim.childImageSharp.fixed}
                   style={{ margin: ".5em 0" }}
              />
            </Link>
            <IconButton disableFocusRipple edge="end" aria-label="menu"
                        style={{ color: transparentBackground ? colors.WHITE_90 : "#0b3c47", textAlign: "center", filter: "brightness(90%)" }}>
              <MenuIcon onClick={toggleDrawer} />
            </IconButton>
            <SwipeableDrawer anchor={"left"} open={drawerIsOpen} onOpen={toggleDrawer} onClose={toggleDrawer}
                             className={classes.mobileMenu}>
              <Container role="presentation" onClick={toggleDrawer}
                         style={{ backgroundColor: "#242526" }}>
                <div style={{ textAlign: "center", padding: "3.5em 0" }}>
                  <Img fixed={data.whiteClaimLogo.childImageSharp.fixed} />
                </div>
              </Container>
              <Divider />
              <Container>
                <List>
                  <Link to={"/"}>
                    <ListItem button key={"home"}>
                      <ListItemIcon><HomeOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={"Inicio"} />
                    </ListItem>
                  </Link>
                  <Link to={"/jobs"}>
                    <ListItem button key={"open_jobs"}>
                      <ListItemIcon><WorkOutlineOutlinedIcon /></ListItemIcon>
                      <ListItemText primary={"Búsquedas activas"} />
                    </ListItem>
                  </Link>
                  <Link to={"/contact"}>
                    <ListItem button key={"contact"}>
                      <ListItemIcon><ModeCommentOutlinedIcon fontSize={"default"} /></ListItemIcon>
                      <ListItemText primary={"Contacto"} />
                    </ListItem>
                  </Link>
                </List>
              </Container>
            </SwipeableDrawer>
          </Toolbar>
        </AppBar>
      </Slide>
      {
        currentPage === "home" &&
        <Hero imageData={data.heroImage.childImageSharp.fluid} style={{ filter: "brightness(15%)" }}>
          <Waypoint onEnter={toggleBackground} onLeave={toggleBackground}>
            <Box style={{ height: "15vh" }}>
              <Grid container direction={"column"} justify="center" alignItems="center" spacing={10}
                    className={classes.main}>
                <p className={classes.title}>{"Conectando"}</p>
                <p className={classes.title}>{"Talentos"}</p>
                <Button variant="outlined" endIcon={<ExpandMoreIcon />}
                        onClick={handleInfoClick}
                        className={classes.informationButton}>
                  {"Más información"}
                </Button>
              </Grid>
            </Box>
          </Waypoint>
        </Hero>
      }
      <>
        {children}
      </>
      {/*</Box>*/}
      <LayoutFooter logo={data.whiteLogo.childImageSharp.fixed} />
    </Box>
  )
}

Layout.propTypes =
  {
    children: PropTypes.node.isRequired,
  }

export default withWidth()(Layout)
